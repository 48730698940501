<template>
  <div v-if="user">
    <b-row>
        <b-col class="col-12 col-md-3 mt-1 mt-md-0">
            <b-media no-body v-if="newUser && newUser.avatar">
                <b-link>
                <b-img
                    ref="previewEl"
                    rounded
                    :src="newUser.avatar"
                    height="200"
                />
                <!-- <feather-icon
                    v-else
                    icon="CodesandboxIcon"
                    size="200"
                /> -->

                
                </b-link>
            </b-media>
            <div v-else style="
                    width:100%;
                    padding: calc(50% - 7px) 0px; 
                    border: 2px white dashed;
                    border-radius: 5px;"
                    class="d-flex justify-content-center align-items-center">
                <span class="align-middle">User profile</span>
            </div>

            <div class="mt-2">
            <!-- upload button -->
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            >
            Upload
            </b-button>
            <!-- <b-form-file
            ref="refInputEl"
            v-model="avatar"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            /> -->
            <!--/ upload button -->

            <!-- reset -->
            <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            >
            Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </div>
        </b-col>
        <b-col class="col col-12 col-md-9 mt-1 mt-md-0">
            <b-card>
                <!-- User Info: Input Fields -->
                <b-form>
                <b-row>

                    <!-- Field: Name -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="First Name"
                        label-for="first name"
                    >
                        <b-form-input
                        id="name"
                        v-model="newUser.firstName"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Last Name"
                        label-for="last name"
                    >
                        <b-form-input
                        id="name"
                        v-model="newUser.lastName"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Job Title"
                        label-for="job title"
                    >
                        <b-form-input
                        id="name"
                        v-model="newUser.jobtitle"
                        />
                    </b-form-group>
                    </b-col>

                    <!-- Field: Email -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Email"
                        label-for="email"
                    >
                        <b-form-input
                        id="email"
                        v-model="newUser.email"
                        type="email"
                        disabled
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Company"
                        label-for="company"
                    >
                        <v-select
                            v-model="company"
                            :options="companyOptions.rows"
                            label="name"
                            disabled
                        />
                    </b-form-group>
                    </b-col>

                   <b-input-group>
                    <b-input-group-prepend is-text>
                        US (+1)
                    </b-input-group-prepend>
                    <cleave
                        id="phone"
                        v-model="newUser.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="(555) 555-5555"
                    />
                    </b-input-group>

                    <!-- Field: Status -->
                    <!-- <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Status"
                        label-for="user-status"
                    >
                        <v-select
                        v-model="userData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                        />
                    </b-form-group>
                    </b-col> -->
                </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>

    

    <!-- Action Buttons -->
    <b-row style="flex-direction:column">
        <b-button
            variant="success"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUser"
        >
            Update User
        </b-button>
    </b-row>
    
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BMediaAside, 
  BMediaBody,
  BLink, 
  BImg,
  BFormFile,
  BCardText,
  BInputGroupPrepend, 
  BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AxiosService from '@/Service/AxiosService'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BMediaAside, 
    BMediaBody,
    BLink, 
    BImg,
    BFormFile,
    BCardText,
    BInputGroupPrepend, 
    BInputGroup,
    Cleave
  },
  props: {
  },
  directives: {
    Ripple,
  },
  data(){
    return {
        newUser:{},
        
        profileFile: null,
        refInputEl:null,
        options:{
            phone: {
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"]
            },
        },
        company:null
    }
  },
  mounted(){
  },    
  methods:{
    // inputImageRenderer(){
    //     useInputImageRenderer(refInputEl, base64 => {
    //         // eslint-disable-next-line no-param-reassign
    //         this.avatar = base64
    //     })
    // },
    clearData(){
        // this.newUser = {};
        // this.company = null;
        this.$router.go(-1)
    },
    getUserInfo(id){
        AxiosService.post('/user', {id}).then(({user, error})=>{
            if(user){
                this.newUser = user;
                this.company = user.entity;
            }
            else{
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Some error occured`,
                            variant: 'danger',
                            text: error,
                        },
                    })
            }
        })
    },
    updateUser(){
        if(this.newUser.firstName && 
        this.newUser.lastName && 
        this.newUser.jobtitle && 
        this.newUser.email && 
        this.newUser.phone && 
        this.company){
            AxiosService.post("/updateUser", {
                id:        this.newUser._id,
                firstName: this.newUser.firstName,
                lastName:  this.newUser.lastName,
                phone:     this.formatNumber(this.newUser.phone),
                avatar:    this.newUser.avatar,
                entity:    this.company,
                jobtitle:  this.newUser.jobtitle
            }).then(({data, error})=>{
                if(data){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `User Updated`,
                            variant: 'success',
                            text: data,
                        },
                    })
                    this.clearData();
                    
                }else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Some error occured`,
                            variant: 'danger',
                            text: error,
                        },
                    })
                }
            })

        }
        else {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Some error occured`,
                    variant: 'danger',
                    text: 'Please check all the fields and try again',
                },
            })
        }
    },
    formatNumber(num){
        let newNum =  num.match(/(\d+)/g)
        return Number(`${newNum[0]}${newNum[1]}${newNum[2]}`)
    }
  },
  mounted(){
    this.getUserInfo(this.$route.query.id);
    // this.$store.dispatch("companies")
  },
  computed: {
    companyOptions(){
        return this.$store.getters.getCompany || [];
    },
    user(){
        return this.$store.getters.getUser || {};
    }
  },
  watch:{
    companyOptions(){
        if(this.user.role=="parent"){
            let self = JSON.parse(JSON.stringify(this.user.entity))
            self.name="SELF"
            this.companyOptions.rows.push(self)
        }
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>